import get from 'lodash.get';

import { IContactRegion } from '@components/sections/Contact/ContactRegion/ContactRegion.d';

export const contactRegion = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData: IContactRegion = {
    type: get(data, 'entityBundle', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    headline: get(data, 'fieldHeadline.processed', ''),
    regions: get(data, 'fieldContactRegionData') ? 
      get(data, 'fieldContactRegionData', []).map(region => ({
        title: get(region, 'entity.fieldTitle', ''),
        image: get(region, 'entity.fieldImage') ? {
          ...get(region, 'entity.fieldImage', ''),
          webp: get(region, 'entity.fieldImage.derivative.url', ''),
        } : undefined,
        partners: get(region, 'entity.fieldContactPartnersData') ? 
          get(region, 'entity.fieldContactPartnersData', []).map(partner => ({
            officeName: get(partner, 'entity.fieldOfficeName', ''),
            country: get(partner, 'entity.fieldCountry', ''),
            email: get(partner, 'entity.fieldEMail', ''),
            phone: get(partner, 'entity.fieldPhone', '')
          })) 
        : undefined
      }))
    : undefined,
  };

  return mappedData;
};
