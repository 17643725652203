import React from 'react';

// === Components === //
import Headline from '@components/common/Headline/Headline';

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/sections/Contact/ContactRegion/ContactRegion.module.scss';

// === Types === //
import { IContactRegion } from '@components/sections/Contact/ContactRegion/ContactRegion.d';
import ImageWebp from '@components/common/ImageWebp/ImageWebp';
import { globalTranslations } from '@helpers/globalTranslations';

const ContactRegion = ({ background, headline, regions }: IContactRegion) => {
  return (
    <div className={cn(styles['contactRegion'], background && 'gray-bg')}>
      {headline && headline !== '' && <Headline title={headline} />}
      <div className={cn('page-wrapper', styles['regionsWrapper'])}>
        {regions &&
          regions.map((region, index) => (
            <div className={cn(styles['region'])} key={index}>
              <h3 className={cn(styles['regionTitle'])}>{region.title}</h3>
              <div className={cn(styles['regionInfo'])}>
                {region.image && (
                  <ImageWebp
                    image={region.image.url}
                    webp={region.image.webp}
                    alt={region.image.alt}
                    title={region.image.title}
                    className={cn(styles['regionMap'])}
                    width="506px"
                    height="298px"
                  />
                )}
                {region.partners && (
                  <ul className={cn(styles['regionPartners'])}>
                    {region.partners.map((partner, index) => (
                      <li key={index}>
                        <h5 className={cn(styles['country'])}>{partner.country}</h5>
                        <h6 className={cn(styles['officeName'])}>{partner.officeName}</h6>
                        <p className={cn(styles['phone'])}>
                          <span>{globalTranslations('fieldPhoneLabel')}</span>
                          <span>
                            <a href={`tel:${partner.phone}`} aria-label="partner-phone">
                              {partner.phone}
                            </a>
                          </span>
                        </p>
                        <p className={cn(styles['mail'])}>
                          <span>{globalTranslations('fieldEmailLabel')}</span>
                          <span>
                            <a href={`mailto:${partner.email}`} aria-label="partner-email">
                              {partner.email}
                            </a>
                          </span>
                        </p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ContactRegion;
