import get from 'lodash.get';

export const carouselData = (alphabeticItems, customOrderItems) => {
  let data = [];
  const customOrderItemsFiltered = customOrderItems
  .filter(card => 
    get(card, 'entity.fieldProductsAvailableSelect') &&
    get(card, 'entity.fieldProductsAvailableSelect.entity.entityPublished', false));

  if (customOrderItemsFiltered && customOrderItemsFiltered.length > 0) {
    // data from new carousel
    data = customOrderItemsFiltered
      .map(card => ({
        title: get(card, 'entity.fieldProductsAvailableSelect.entity.title', ''),
        body: get(card, 'entity.fieldProductsAvailableSelect.entity.fieldSmallCard.entity.fieldCardCopyNotHtml', ''),
        url: get(card, 'entity.fieldProductsAvailableSelect.entity.path.alias', '/'),
        image: get(card, 'entity.fieldProductsAvailableSelect.entity.fieldSmallCard.entity.fieldImage', undefined) ? {
          url: get(card, 'entity.fieldProductsAvailableSelect.entity.fieldSmallCard.entity.fieldImage.url', ''),
          alt: get(card, 'entity.fieldProductsAvailableSelect.entity.fieldSmallCard.entity.fieldImage.alt', ''),
          title: get(card, 'entity.fieldProductsAvailableSelect.entity.fieldSmallCard.entity.fieldImage.title', ''),
          webp: get(card, 'entity.fieldProductsAvailableSelect.entity.fieldSmallCard.entity.fieldImage.derivative.url', ''),
        } : undefined,
        cta: {
          label: get(card, 'entity.fieldProductsAvailableSelect.entity.fieldSmallCard.entity.fieldCtaText'),
          url: get(card, 'entity.fieldProductsAvailableSelect.entity.path.alias', '/'),
        }
      }))

  } else {
    // data from old carousel
    data = alphabeticItems
      .map(card => ({
        title: get(card, 'entity.title', ''),
        body: get(card, 'entity.fieldSmallCard.entity.fieldCardCopyNotHtml', ''),
        image: {
          ...get(card, 'entity.fieldSmallCard.entity.fieldImage', ''),
          webp: get(card, 'entity.fieldSmallCard.entity.fieldImage.derivative.url', ''),
        },
        cta: {
          label: get(card, 'entity.fieldSmallCard.entity.fieldCtaText'),
          url: get(card, 'entity.path.alias', '/'),
        }
      }))
  }

  return data;
}