import React, { Fragment, useState } from 'react';

// === Components === //
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Input from '@components/common/Input/Input';
import Textarea from '@components/common/Textarea/Textarea';
import Checkbox from '@components/common/Checkbox/Checkbox';
import Button from '@components/common/Button/Button';
import Loader from '@components/common/Loader/Loader';
import CustomReCaptchaBanner from '@components/common/CustomReCaptchaBanner/CustomReCaptchaBanner';

// === Helpers === //
import cn from 'classnames';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from '@helpers/validation';
import { globalTranslations } from '@helpers/globalTranslations';
import { setDataLayer } from '@helpers/form';
import * as utils from '@utils';

// === Styles === //
import * as styles from '@components/sections/Contact/ContactPageForm/ContactPageForm.module.scss';

// === Types === //
import { IContactPageForm } from '@components/sections/Contact/ContactPageForm/ContactPageForm.d';

const ContactPageForm = ({
  description,
  headline,
  textareaAdditionalText,
  form,
  anchorId,
}: IContactPageForm) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [successSubmit, setSuccessSubmit] = useState<boolean>(false);
  const requiredFieldText = globalTranslations('fieldRequiredField');

  // reCaptcha v3
  const [reCaptchaError, setReCaptchaError] = useState<boolean>(false);
  const recaptcha_site_key = globalTranslations('fieldRecaptchaSiteKey');
  const recaptcha_error_message = globalTranslations('fieldRecaptchaError');
  const recaptcha_text = (globalTranslations('fieldRecaptchaCopy') as any)?.processed;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema(form?.fields || [])) });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data) => {
    setLoading(true);
    setReCaptchaError(false);

    if (recaptcha_site_key && recaptcha_site_key !== '') {
      const reCaptchaToken = await (executeRecaptcha as any)('contactForm');

      if (reCaptchaToken && reCaptchaToken !== '') {
        formFetchData(data, reCaptchaToken);
      } else {
        setReCaptchaError(true);
        setLoading(false);
      }
    } else {
      formFetchData(data, '');
    }
  };

  const formFetchData = (data, gReCaptchaResponse) => {
    const formData = {
      fieldFormName: form?.formName || '',
      ca__chat: Cookies.get('__ca__chat') || '',
      fields: Object.keys(data).map((fieldName) => ({
        fieldFieldType: form?.fields.find((field) => field.name === fieldName)?.fieldFieldType,
        fieldFieldName: fieldName,
        value: data[fieldName],
      })),
      referer: typeof window !== 'undefined' ? window.location.href : '',
      ...utils.odooData(form?.language || ''),
      formId: form?.id,
      gReCaptchaResponse,
    };

    axios
      .post(`${process.env.API_FORM_URL}`, {
        ...formData,
      })
      .then((response) => {
        if (response.status === 201 && response.data && response?.data?.results?.email === 'OK') {
          setSuccessSubmit(true);
          setDataLayer({
            ...formData,
            formType: 'contact_form',
            classes: 'contact-page__form',
            formSubmit: 'submit',
            odoo_lead_id: response?.data?.results?.odoo_com,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div className={cn(styles['formWrapper'])} id={anchorId || ''}>
      <div className={cn('page-wrapper')}>
        <div className={cn(styles['formHeadline'])}>
          <h2 className={cn('safe-html-inherit-fonts')}>
            {successSubmit && form ? utils.safeHtml(form.success.title) : utils.safeHtml(headline)}
          </h2>
          <div className={cn(styles['formDescription'], 'safe-html-inherit-fonts')}>
            {successSubmit && form
              ? utils.safeHtml(form.success.description)
              : utils.safeHtml(description)}
          </div>
        </div>

        {form && !successSubmit && (
          <div className={cn(styles['form'])}>
            {form.title && form.title !== '' && (
              <div className={cn(styles['formSubtitle'])}>{utils.safeHtml(form.title)}</div>
            )}

            <form onSubmit={handleSubmit(onSubmit)} className="contact-page__form">
              {form.fields.map((field, index) => (
                <Fragment key={index}>
                  {field.type === 'input' && (
                    <Input
                      name={field.name}
                      label={field.label}
                      placeholder={field.placeholder}
                      register={register(field.name)}
                      errorMessage={errors[field.name]?.message}
                      mandatory={
                        field.validation.find((v) => v.type === 'mandatory') ? true : false
                      }
                    />
                  )}
                  {field.type === 'textarea' && (
                    <Textarea
                      name={field.name}
                      label={field.label}
                      placeholder={field.placeholder}
                      register={register(field.name)}
                      errorMessage={errors[field.name]?.message}
                      mandatory={
                        field.validation.find((v) => v.type === 'mandatory') ? true : false
                      }
                      additionalTitle={
                        form.fields.findIndex((f) => f.type === 'textarea') === index
                          ? textareaAdditionalText
                          : undefined
                      }
                    />
                  )}
                  {field.type === 'checkbox' && (
                    <Checkbox
                      name={field.name}
                      label={field.label}
                      register={register(field.name)}
                      errorMessage={errors[field.name]?.message}
                      mandatory={
                        field.validation.find((v) => v.type === 'mandatory') ? true : false
                      }
                      className={
                        form.fields.findIndex((f) => f.type === 'checkbox') === index
                          ? 'firstCheckbox'
                          : ''
                      }
                    />
                  )}
                </Fragment>
              ))}

              {recaptcha_site_key &&
                recaptcha_site_key !== '' &&
                recaptcha_text &&
                recaptcha_text !== '' && <CustomReCaptchaBanner text={recaptcha_text} />}

              {requiredFieldText && requiredFieldText !== '' && (
                <p className={cn(styles['formRequiredFieldText'])}>
                  <span>*</span>
                  {requiredFieldText}
                </p>
              )}

              <div id="recaptcha-container"></div>
              {reCaptchaError && <p className="reCaptcha-error">{recaptcha_error_message}</p>}

              <Button type="submit" loading={loading}>
                {loading ? <Loader /> : form.button}
              </Button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactPageForm;
