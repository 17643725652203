import React, { Fragment } from 'react';

// === Components === //
import Hero from '@components/sections/Hero/Hero';
import ContactOffices from '@components/sections/Contact/ContactOffices/ContactOffices';
import ContactSubmenu from '@components/sections/Contact/ContactSubmenu/ContactSubmenu';
import ContactRegion from '@components/sections/Contact/ContactRegion/ContactRegion';
import ContactLiveDemoRegister from '@components/sections/Contact/ContactLiveDemoRegister/ContactLiveDemoRegister';
import ContactPageForm from '@components/sections/Contact/ContactPageForm/ContactPageForm';
import ContactBookAnOnlineDemo from '@components/sections/Contact/ContactBookAnOnlineDemo/ContactBookAnOnlineDemo';
import Faq from '@components/sections/Faq/Faq';
import Newsletter from '@components/sections/Newsletter/Newsletter';

// === Helpers === //
import cn from 'classnames';
import { heroVariant } from '@helpers/heroVariants';

// === Styles === //
import * as styles from '@components/layout/PageComponents/PageComponents.module.scss';

// === Types === //
import { HeroData, PageData } from '@type/page-components.d';

const ContactComponents = ({ components, type, slug }: PageData) => {
  return (
    <div className={cn(styles['pageComponentsWrapper'])}>
      {components.map((component, index) => (
        <Fragment key={index}>
          {component && component.type && component.type !== '' && (
            <>
              {(component.type === 'main_banner_variant_1' ||
                component.type === 'main_banner_variant_2' ||
                component.type === 'main_banner_variant_3') && (
                <Hero
                  variant={heroVariant['main_banner_variant_5'].variant}
                  backgroundVariant={heroVariant['main_banner_variant_5'].bgVariant}
                  title={(component as HeroData).title}
                  description={(component as HeroData).description}
                  image={(component as HeroData).image || undefined}
                  buttons={(component as HeroData).buttons || []}
                  backgroundVideo={(component as HeroData).backgroundVideo || undefined}
                />
              )}
              {component.type === 'global_office' && <ContactOffices {...component} />}
              {component.type === 'contact_submenu' && (
                <ContactSubmenu slug={slug} {...component} />
              )}
              {component.type === 'contact_region' && <ContactRegion {...component} />}
              {component.type === 'live_demo_register' && (
                <ContactLiveDemoRegister {...component} />
              )}
              {(component.type === 'get_a_quote_form' || component.type === 'write_to_us_form') && (
                <ContactPageForm {...component} />
              )}
              {/* {component.type === 'book_an_online_demo' && (
                <ContactBookAnOnlineDemo {...component} />
              )} */}
              {component.type === 'faq' && <Faq {...component} />}
              {component.type === 'newsletter' && <Newsletter {...component} />}
            </>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default ContactComponents;
