import get from 'lodash.get';

import { IContactBannerInfo } from '@components/sections/Contact/ContactBannerInfo/ContactBannerInfo.d'

export const bannerContactInfo = (nodeData, phoneName: string, emailName: string) => {
  const phone = get(nodeData, `${phoneName}`, '');
  const email = get(nodeData, `${emailName}`, '');

  const componentData: IContactBannerInfo = { phone, email }

  return componentData;
}