// extracted by mini-css-extract-plugin
export var contactRegion = "ContactRegion-module--contact-region--p+5Kx";
export var country = "ContactRegion-module--country--+bqG0";
export var mail = "ContactRegion-module--mail--alCmr";
export var officeName = "ContactRegion-module--office-name--Nv8qZ";
export var phone = "ContactRegion-module--phone--QreqB";
export var region = "ContactRegion-module--region--PFz+i";
export var regionInfo = "ContactRegion-module--region-info--+Qxkd";
export var regionMap = "ContactRegion-module--region-map--UEjLf";
export var regionPartners = "ContactRegion-module--region-partners--0z9vK";
export var regionTitle = "ContactRegion-module--region-title--AImF9";
export var regionsWrapper = "ContactRegion-module--regions-wrapper--qcYuv";