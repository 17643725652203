import get from 'lodash.get';

import { IContactSubmenu } from '@components/sections/Contact/ContactSubmenu/ContactSubmenu.d';

export const contactSubmenu = (taxonomyData) => {
  const mappedData: IContactSubmenu = {
    type: 'contact_submenu',
    submenuLinks: taxonomyData.map((link) => ({
      link: get(link, 'fieldPage.entity.path.alias'),
      name: get(link, 'entityLabel'),
    })),
  };

  return mappedData;
};
