import React from 'react';

// === Components === //
import Headline from '@components/common/Headline/Headline';

// === Helpers === //
import cn from 'classnames';
import * as utils from '@utils';

// === Styles === //
import * as styles from '@components/sections/Contact/ContactOffices/ContactOffices.module.scss';

// === Types === //
import {
  IContactOffices,
  IContactOfficesData,
  IContactOfficesOffice,
} from '@components/sections/Contact/ContactOffices/ContactOffices.d';
import { globalTranslations } from '@helpers/globalTranslations';

const OfficesList = (officeData: IContactOfficesData) => {
  return (
    <div className={cn(styles['officesListBox'])}>
      <div className={cn(styles['officesListHeadlineWrapper'])}>
        <h3 className={cn('safe-html-inherit-fonts', styles['officesListHeadline'])}>
          {utils.safeHtml(officeData.headline)}
        </h3>
      </div>
      <div className={cn(styles['officesList'])}>
        {officeData.offices.map((office: IContactOfficesOffice, index: number) => (
          <div key={index} className={cn(styles['office'])}>
            {office.country && office.country !== '' && (
              <p className={cn(styles['officeCountry'])}>{office.country}</p>
            )}
            {office.companyName && office.companyName !== '' && (
              <p className={cn(styles['officeCompanyName'])}>{office.companyName}</p>
            )}
            {office.phone && office.phone !== '' && (
              <p className={cn(styles['officePhone'])}>
                <span>{globalTranslations('fieldPhoneLabel')}</span>
                <span>
                  <a href={`tel:${office.phone}`} aria-label="phone">
                    {office.phone}
                  </a>
                </span>
              </p>
            )}
            {office.email && office.email !== '' && (
              <p className={cn(styles['officeEmail'])}>
                <span>{globalTranslations('fieldEmailLabel')}</span>
                <span>
                  <a href={`mailto:${office.email}`} aria-label="email">
                    {office.email}
                  </a>
                </span>
              </p>
            )}
            {office.officeName && office.officeName !== '' && (
              <p className={cn(styles['officeName'])}>{office.officeName}</p>
            )}
            {office.street && office.street !== '' && (
              <p className={cn(styles['officeStreet'])}>{office.street}</p>
            )}
            {office.postalCode && office.postalCode !== '' && (
              <p className={cn(styles['officePostalCode'])}>{office.postalCode}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const ContactOffices = ({ headline, globalOffices, localOffices }: IContactOffices) => {
  return (
    <div className={cn(styles['officesWrapper'], 'gray-bg')}>
      <Headline title={headline} />
      <div className={cn('page-wrapper', styles['officesInner'])}>
        <OfficesList {...globalOffices} />
        <OfficesList {...localOffices} />
      </div>
    </div>
  );
};

export default ContactOffices;
