import get from 'lodash.get';

import { globalTranslations } from '@helpers/globalTranslations';
import { carouselData } from '@helpers/smallCarousel';
import { IContactLiveDemoRegister } from '@components/sections/Contact/ContactLiveDemoRegister/ContactLiveDemoRegister.d';

export const contactLiveDemoRegister = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData: IContactLiveDemoRegister = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    copy: get(data, 'fieldCopy.processed', ''),
    galleryAccordionTitle: get(data, 'fieldLabel', ''),
    gallery: {
      headline: get(data, 'fieldPhotoGalery.entity.fieldHeadline.processed', ''),
      carousel: get(data, 'fieldPhotoGalery.entity.fieldPhotoGalleryItem', []).map((slide) => ({
        subheadline: get(slide, 'entity.fieldSubtitle', ''),
        image: get(slide, 'entity.fieldImage')
          ? {
              url: get(slide, 'entity.fieldImage.url', ''),
              alt: get(slide, 'entity.fieldImage.alt', ''),
              title: get(slide, 'entity.fieldImage.title', ''),
              webp: get(slide, 'entity.fieldImage.derivative.url', ''),
            }
          : undefined,
        preferedLanguagesHeadline: globalTranslations('fieldPreferredLanguage'),
        preferedLanguages: get(slide, 'entity.fieldPreferredLanguage', ''),
        products: carouselData(
          get(data, 'entity.fieldProductsAvailable', []),
          get(data, 'entity.fieldProductSelect', [])
        ),
      })),
    },
    ycbm: {
      script: get(data, 'fieldYcbm', ''),
      headline: get(data, 'fieldTitle', ''),
    },
  };

  return mappedData;
};
