import React from 'react';

// === Helpers === //
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/sections/Contact/ContactSubmenu/ContactSubmenu.module.scss';

// === Types === //
import { ContactSubmenuExtend } from '@components/sections/Contact/ContactSubmenu/ContactSubmenu.d';

const ContactSubmenu = ({ submenuLinks, slug }: ContactSubmenuExtend) => {
  return (
    <div className={cn(styles['submenuContainer'])}>
      <div className={cn('page-wrapper')}>
        <div className={styles['submenuWrapper']}>
          {submenuLinks.map((item, i) => (
            <a
              href={item.link}
              className={cn(
                styles['submenuItem'],
                styles['submenuItem_divider'],
                slug === item.link ? styles['isActive'] : ''
              )}
              key={i}
            >
              <p>{item.name}</p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactSubmenu;
