// extracted by mini-css-extract-plugin
export var office = "ContactOffices-module--office--b0TMq";
export var officeCompanyName = "ContactOffices-module--office-company-name--lUVa+";
export var officeCountry = "ContactOffices-module--office-country--e0ZHx";
export var officeEmail = "ContactOffices-module--office-email--oVAkW";
export var officeName = "ContactOffices-module--office-name--7mtzk";
export var officePhone = "ContactOffices-module--office-phone--9ZPvB";
export var officePostalCode = "ContactOffices-module--office-postal-code--7yyL5";
export var officeStreet = "ContactOffices-module--office-street--k0a1D";
export var officesInner = "ContactOffices-module--offices-inner--RvyB6";
export var officesList = "ContactOffices-module--offices-list--n1L1r";
export var officesListBox = "ContactOffices-module--offices-list-box--T6Xmq";
export var officesListHeadline = "ContactOffices-module--offices-list-headline--x0Wli";
export var officesListHeadlineWrapper = "ContactOffices-module--offices-list-headline-wrapper--70NBR";
export var officesWrapper = "ContactOffices-module--offices-wrapper--AXpTk";