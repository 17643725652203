import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import ContactComponents from '@components/layout/ContactComponents/ContactComponents';

// === Content === //
import { ContactPageDTO } from '@DTO/ContactPageDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface ContactPageProps {
  pageContext?: TemplatePageContextData;
}

const ContactPage = ({ pageContext }: ContactPageProps) => {
  const pageData = ContactPageDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(pageData.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={pageData.metaData}
        breadcrumbs={pageData.breadcrumbs}
        stickedForm={pageData.stickedForm}
        bannerContactInfo={pageData.bannerContactInfo}
        navLangPath={pageData.langPath}
      >
        <ContactComponents
          components={pageData.components}
          type="contact"
          slug={`/${pageContext?.slug || ''}`}
        />
      </Layout>
    </>
  );
};

export default ContactPage;
