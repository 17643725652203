import get from 'lodash.get';

import { IContactOffices } from '@components/sections/Contact/ContactOffices/ContactOffices.d';

export const contactOffices = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData: IContactOffices = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    globalOffices: {
      headline: get(data, 'fieldSubheadline', ''),
      offices: get(data, 'fieldContactOffice', []).map(office => ({
        companyName: get(office, 'entity.fieldCompanyName', ''),
        country: get(office, 'entity.fieldCountry', ''),
        email: get(office, 'entity.fieldEMail', ''),
        officeName: get(office, 'entity.fieldOfficeName', ''),
        phone: get(office, 'entity.fieldPhone', ''),
        postalCode: get(office, 'entity.fieldPostalCode', ''),
        street: get(office, 'entity.fieldStreet', ''),
      }))
    },
    localOffices: {
      headline: get(data, 'fieldLocalOffices.entity.fieldSubheadline', ''),
      offices: get(data, 'fieldLocalOffices.entity.fieldContactOffice', []).map(office => ({
        companyName: get(office, 'entity.fieldCompanyName', ''),
        country: get(office, 'entity.fieldCountry', ''),
        email: get(office, 'entity.fieldEMail', ''),
        officeName: get(office, 'entity.fieldOfficeName', ''),
        phone: get(office, 'entity.fieldPhone', ''),
        postalCode: get(office, 'entity.fieldPostalCode', ''),
        street: get(office, 'entity.fieldStreet', ''),
      }))
    }
  };

  return mappedData;
};
