import get from 'lodash.get';

import { IContactBookAnOnlineDemo } from '@components/sections/Contact/ContactBookAnOnlineDemo/ContactBookAnOnlineDemo.d';

export const contactBookAnOnlineDemo = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData: IContactBookAnOnlineDemo = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldCopy.processed', ''),
    code: get(data, 'fieldCode', ''),
    cardTextImage: {
      title: get(data, 'fieldTitle', ''),
      description: get(data, 'fieldDescription.processed', ''),
      image: get(data, 'fieldImage', undefined) ? {
        alt: get(data, 'fieldImage.alt', ''),
        title: get(data, 'fieldImage.title', ''),
        url: get(data, 'fieldImage.url', ''),
        webp: get(data, 'fieldImage.derivative.url', ''),
      } : undefined
    }
  };

  return mappedData;
};
