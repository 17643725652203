import get from 'lodash.get';

// === Data === //
import { contactPageData } from '@graphql/ContactPage';

// === Helpers === //
import { filterByLanguage, getNodeEntity } from '@utils';

// === Sections === //
import { metatags } from '@DTO/sections/Metatags';
import { mainBanner } from '@DTO/sections/MainBanner';
import { contactOffices } from '@DTO/sections/contact/ContactOffices';
import { contactRegion } from '@DTO/sections/contact/ContactRegion';
import { contactSubmenu } from '@DTO/sections/contact/ContactSubmenu';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { contactLiveDemoRegister } from '@DTO/sections/contact/ContactLiveDemoRegister';
import { contactPageForm } from '@DTO/sections/contact/ContactPageForm';
import { contactBookAnOnlineDemo } from '@DTO/sections/contact/ContactBookAnOnlineDemo';
import { faq } from '@DTO/sections/FAQ';
import { bannerContactInfo } from '@DTO/sections/contact/BannerContactInfo';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { languagePath } from '@DTO/sections/LanguagePath';

export const ContactPageDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const siteDataQuery = contactPageData();
    currentPageData = siteDataQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const nodeTemplate = get(currentPageData, 'drupalData.template.entities', []).filter(filterByLanguage)[0];
  const nodeTaxonomy = get(currentPageData, 'drupalData.taxonomyTermQuery.entities', []);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(currentPageData, 'drupalData.form'),
      }
    : undefined;
  const componentType = get(nodeEntity, 'fieldSectionContact.entity.entityBundle', '');
  const components = {
    live_demo_register: contactLiveDemoRegister(nodeEntity, 'fieldSectionContact'),
    get_a_quote_form: contactPageForm(nodeEntity, 'fieldSectionContact', additionalData),
    write_to_us_form: contactPageForm(nodeEntity, 'fieldSectionContact', additionalData),
    book_an_online_demo: contactBookAnOnlineDemo(nodeEntity, 'fieldSectionContact'),
  };

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      contactSubmenu(nodeTaxonomy),
      components[componentType],
      contactOffices(nodeTemplate, 'fieldGlobalOffice'),
      contactRegion(nodeTemplate, 'fieldContactRegion'),
      faq(nodeEntity, 'fieldFaq'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
    bannerContactInfo: bannerContactInfo(nodeEntity, 'fieldPhoneLabel', 'fieldEmailLabel'),
  };

  return {
    ...queryData,
  };
};
