import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const contactPageData = () => {
  const data = useStaticQuery(graphql`
    query contactPageQuery {
      drupalData {
        nodeQuery(filter: { conditions: { field: "type", value: "contact_page" } }, limit: 1000) {
          entities {
            ... on drupalData_NodeContactPage {
              entityLabel

              fieldFaq {
                entity {
                  ...ParagraphFaq
                }
              }
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }

              fieldSectionContact {
                entity {
                  ...ParagraphGetAQuoteForm
                  # ...ParagraphLiveDemoRegister
                  ...ParagraphWriteToUsForm
                  # ...ParagraphBookAnOnlineDemo
                }
              }
              fieldFormOffer
              fieldPhoneLabel
              fieldEmailLabel
              fieldForm {
                entity {
                  uuid
                }
              }

              # important !!!
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }

        template: nodeQuery(
          filter: { conditions: { field: "type", value: "contact_template" } }
          limit: 1000
        ) {
          entities {
            ... on drupalData_NodeContactTemplate {
              entityLabel
              fieldGlobalOffice {
                entity {
                  ...ParagraphGlobalOffice
                }
              }
              fieldContactRegion {
                entity {
                  ...ParagraphContactRegion
                }
              }
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }

        taxonomyTermQuery(
          limit: 1000
          filter: { conditions: { field: "vid", value: "contact_submenu" } }
        ) {
          entities {
            ... on drupalData_TaxonomyTermContactSubmenu {
              entityLabel
              entityBundle
              fieldPage {
                entity {
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
