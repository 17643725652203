import get from 'lodash.get';

import { form } from '@DTO/sections/form/Form';
import { IContactPageForm } from '@components/sections/Contact/ContactPageForm/ContactPageForm.d';
import { getDataFromPreview } from '@helpers/form';

export const contactPageForm = (nodeData, fieldName: string, previewData?: unknown) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);
  const formId = get(data, 'fieldForm.entity.uuid', '');

  const componentData: IContactPageForm = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldCopy.processed', ''),
    textareaAdditionalText: {
      title: get(data, 'fieldTitle', ''),
      description: get(data, 'fieldDescription.processed', ''),
      disclaimer: get(data, 'fieldFormDisclaimer', ''),
    },
    form: form(formId, getDataFromPreview(previewData)),
    anchorId: get(data, 'fieldAnchorId'),
  };

  return componentData;
};
